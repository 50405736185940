<template>
  <div class="container-historico">
    <span
      class="material-symbols-outlined icone-historico"
      id="icone-historico"
      @click="abrirHistorico"
      :class="{ disabled: resultadoListaThreadsGeral.length == 0 }"
    >
      history_2
    </span>
    <b-tooltip
      :target="'icone-historico'"
      triggers="hover"
      placement="right"
      variant="secondary"
      class="tooltip-acoes"
      boundary="window"
    >
      Histórico dos últimos 60 dias
    </b-tooltip>
    <span
      class="material-symbols-outlined icone-historico"
      id="icone-nova"
      v-if="!abrirListaHistorico"
      @click="novaConversa()"
    >
      add_comment
      <b-tooltip
        :target="'icone-nova'"
        triggers="hover"
        placement="right"
        variant="secondary"
        class="tooltip-acoes"
        boundary="window"
      >
        Novo chat
      </b-tooltip>
    </span>
    <div class="lista-historico" v-if="abrirListaHistorico">
      <div class="item-historico">
        <span class="item" @click="novaConversa()">
          <span class="material-symbols-outlined icone-chat novo"> add_comment </span>
          <span class="texto"> Novo chat </span>
        </span>
      </div>
      <div
        class="item-historico"
        v-for="item in listaThreadsOrdenada"
        :key="item.thread_id"
        :class="{
          ativo: historicoSelecionado == item.thread_id,
        }"
      >
        <span
          class="item"
          :class="{
            disabled: !item.flag_thread_ativa,
          }"
          @click="selecionaHistorico(item)"
        >
          <span class="material-symbols-outlined icone-chat"> mark_chat_read </span>
          <span class="texto">
            <span class="descricao">
              {{ formatarDataBr(item.data_ultima_mensagem) }}
            </span>
            <span class="descricao">
              {{ formatarHoraBr(item.data_ultima_mensagem) }}
            </span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { chatOutboxHistoricoStore } from "@/stores/chat-Outbox-historico.js";

import { chatOutboxFormatacoesStore } from "@/stores/chat-Outbox-formatacoes.js";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";
import CarrosselSingu from "./CarrosselSingu.vue";
import ListaPerguntasSugeridas from "./ListaPerguntasSugeridas.vue";

export default {
  name: "HistoricoChat",

  components: {},

  props: {},

  data() {
    return {
      historicoSelecionado: "",
      abrirListaHistorico: false,
    };
  },

  watch: {},

  methods: {
    abrirHistorico() {
      this.abrirListaHistorico = !this.abrirListaHistorico;
    },

    novaConversa() {
      this.$emit("novoChat");
    },
    selecionaHistorico(item) {
      this.historicoSelecionado = "";
      this.historicoSelecionado = item.thread_id;
      this.$emit("enviaHistoricoSelecionado", item);
    },

    formatarDataBr(dataString) {
      if (dataString != null && dataString != "") {
        const data = new Date(dataString);
        const ano = data.getFullYear();
        const mes = String(data.getMonth() + 1).padStart(2, "0");
        const dia = String(data.getDate()).padStart(2, "0");

        return `${dia}/${mes}/${ano}`;
      } else {
        return "";
      }
    },
    formatarHoraBr(dataString) {
      if (dataString != null && dataString != "") {
        const data = new Date(dataString);

        const horas = String(data.getHours()).padStart(2, "0");
        const minutos = String(data.getMinutes()).padStart(2, "0");
        const segundos = String(data.getSeconds()).padStart(2, "0");
        return `${horas}:${minutos}:${segundos}`;
      } else {
        return "";
      }
    },

    ...mapActions(chatOutboxHistoricoStore, ["recuperarListaThreads"]),
  },

  computed: {
    listaThreadsOrdenada() {
      if (this.resultadoListaThreadsGeral.length > 0) {
        return [...this.resultadoListaThreadsGeral].sort((a, b) => {
          const dataA = new Date(a.data_ultima_mensagem);
          const dataB = new Date(b.data_ultima_mensagem);

          return dataB - dataA;
        });
      }
      return [];
    },

    ...mapWritableState(chatOutboxHistoricoStore, {
      resultadoListaThreadsGeral: "resultadoListaThreadsGeral",
      resultadoListaHistoricoGeral: "resultadoListaHistoricoGeral",
      resultadoListaHistoricoContagemGeral: "resultadoListaHistoricoContagemGeral",
    }),
  },

  async mounted() {
    this.recuperarListaThreads();
  },
};
</script>

<style lang="scss" scoped>
.container-historico {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 70%;

  position: absolute;
  left: 2%;
  top: 15%;
}

.lista-historico {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px;
  gap: 5px;

  max-height: 70%;
  overflow-y: auto;
}

.icone-historico {
  cursor: pointer;
  margin: 5px;
  color: gray;
  background-color: #dfdfdf;
  border-radius: 50%;
  padding: 8px;
  transition: 0.3s;

  &:hover {
    color: rgb(39, 39, 39);
    transform: scale(1.05);
    background-color: #d3d3d3;
  }
}

.item-historico {
  font-size: 14px;
  font-weight: 300;
  color: gray;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  transition: 0.3s;
  min-width: 170px;
  min-height: 40px;
  display: flex;
  align-items: center;

  &:hover {
    color: black;
    transform: scale(1.05);
    background-color: #d3d3d3;
    border-radius: 10px;
  }

  &.ativo {
    color: black;
    background-color: #dfdfdf;
    border-radius: 10px;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.texto {
  display: flex;
  flex-direction: column;
  line-height: 16px;
}

.icone-chat {
  font-size: 22px;
  opacity: 0.6;
  &.novo {
    font-size: 24px;
  }
}

.disabled {
  pointer-events: none;
  color: #a1a1a1;
}

.lista-historico::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.lista-historico::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 10px;
}

.lista-historico::-webkit-scrollbar-track {
  background: #dadada;
}
</style>
