var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"janela-chat",style:(_vm.tipoAssistenteSelecionado == 'singuOutbox' ? 'width: 60%' : 'width: 40%')},[_c('div',{ref:"chatContainer",staticClass:"corpo-chat",on:{"scroll":_vm.monitoraScrollUsuario,"wheel":_vm.monitoraScrollUsuario}},[(
        (_vm.tipoAssistenteSelecionado == 'singuOutbox' &&
          _vm.resultadoHistoricoContagemGeral.QuantidadeRegistros > 10 &&
          !_vm.travaCarregamentoHistorico) ||
        (_vm.tipoAssistenteSelecionado == 'singuConsorcio' &&
          _vm.resultadoHistoricoContagemConsorcio.QuantidadeRegistros > 0 &&
          !_vm.travaCarregamentoHistorico)
      )?_c('div',{staticClass:"carregar-mensagens",class:{
        'mensagem-carregando': _vm.carregandoMensagensAntigas,
      },on:{"click":function($event){return _vm.enviaCarregarMensagens()}}},[_c('span',{staticClass:"material-symbols-outlined icone-carregar",class:{ 'icone-girando': _vm.carregandoMensagensAntigas }},[_vm._v(" sync ")]),_vm._v(" Carregar mensagens antigas ")]):_vm._e(),_vm._l((_vm.historicoChat),function(mensagem,indice){return _c('div',{key:indice,staticClass:"box-mensagem",style:({
        'justify-content':
          mensagem.enviado === true
            ? 'flex-end'
            : mensagem.enviado === false
            ? 'flex-start'
            : 'center',
      })},[(!mensagem.enviado)?_c('span',{staticClass:"fundo-logo-ia"},_vm._l((_vm.imagens),function(imagem){return _c('img',{key:imagem.key,staticClass:"logo-ia",class:{
            ativo: _vm.imagemAtual === imagem.key,
            'robo-gif': !imagem.key.includes('Padrao'),
          },attrs:{"src":imagem.src}})}),0):_vm._e(),(mensagem.divisor)?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"center"}},[(mensagem.divisor)?_c('div',{staticClass:"mensagem-divisor",domProps:{"innerHTML":_vm._s(mensagem.texto)}}):_vm._e()]):_c('div',{class:{
          'mensagem-chat': true,
          enviado: mensagem.enviado,
          recebido: !mensagem.enviado,
        }},[(mensagem.tipo == 'texto')?_c('div',{domProps:{"innerHTML":_vm._s(mensagem.texto)},on:{"click":function($event){return _vm.handleClick($event)}}}):_vm._e(),(mensagem.tipo === 'imagem' && mensagem.imagem != '')?_c('div',[_c('img',{staticClass:"chat-imagem",attrs:{"src":'data:image/png;base64,' + mensagem.imagem,"alt":"Imagem do chat"},on:{"click":function($event){return _vm.abrirPopup('data:image/png;base64,' + mensagem.imagem)}}})]):_vm._e(),_c('div',{staticClass:"hora-msg"},[(_vm.historicoAtivo)?_c('span',[_vm._v(_vm._s(mensagem.data))]):_vm._e(),_vm._v(" "+_vm._s(mensagem.hora)+" ")])])])})],2),_c('div',{staticClass:"chat-container"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.entradaChatTemp),expression:"entradaChatTemp"}],staticClass:"entrada-chat",attrs:{"placeholder":"Mensagem...","maxlength":"1000"},domProps:{"value":(_vm.entradaChatTemp)},on:{"keydown":_vm.verificarEnvio,"input":[function($event){if($event.target.composing)return;_vm.entradaChatTemp=$event.target.value},_vm.checkTransicao]}}),_c('div',{staticClass:"contador"},[_vm._v(_vm._s(_vm.entradaChatTemp.length)+"/1000")]),(!_vm.carregandoResposta && !_vm.carregandoRequisicao)?_c('span',{staticClass:"material-symbols-outlined botao-enviar",class:{
        disabled: _vm.entradaChatTemp == '' || _vm.carregandoResposta || !_vm.termosUsoAssistente,
      },on:{"click":function($event){return _vm.enviarChat()}}},[_vm._v(" send ")]):(_vm.carregandoResposta && !_vm.carregandoRequisicao)?_c('span',{staticClass:"material-symbols-outlined botao-enviar",class:{ disabled: _vm.carregandoRequisicao },on:{"click":function($event){return _vm.stopSimulacao()}}},[_vm._v(" stop_circle ")]):_vm._e(),(_vm.carregandoRequisicao)?_c('md-progress-spinner',{staticClass:"botao-enviar loading",attrs:{"md-diameter":25,"md-stroke":2,"md-mode":"indeterminate"}}):_vm._e(),_c('div',{staticClass:"chat-aviso"},[_vm._v(" Singú está sujeito a falhas. Sempre confira as informações. ")]),_c('div',{staticClass:"chat-ajuda"},[_c('img',{ref:"iconeAjuda",staticClass:"icone-ajuda",attrs:{"src":_vm.iconeCarrossel,"alt":"icone ajuda assistente outbox"},on:{"click":function($event){_vm.abreCarrossel = !_vm.abreCarrossel}}}),(_vm.tipoAssistenteSelecionado == 'singuConsorcio')?_c('img',{ref:"iconeAjudaPerguntas",staticClass:"icone-ajuda",staticStyle:{"top":"1px"},attrs:{"src":_vm.iconeListaPerguntas,"alt":"icone sugestões assistente outbox"},on:{"click":function($event){_vm.abreListaPerguntas = !_vm.abreListaPerguntas}}}):_vm._e()])],1),(_vm.imagemPopup)?_c('div',{staticClass:"popup-overlay",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.fecharPopup.apply(null, arguments)}}},[_c('div',{ref:"popupContent",staticClass:"popup-content"},[_c('img',{ref:"imagemExpandida",staticClass:"imagem-expandida",style:({
          transform: `scale(${_vm.zoomLevel})`,
        }),attrs:{"src":_vm.imagemPopup,"alt":"Imagem expandida"},on:{"wheel":_vm.handleZoomWheel}})]),_c('div',{staticClass:"zoom-controls"},[_c('span',{staticClass:"material-symbols-outlined botoes-ajuste",on:{"click":_vm.fecharPopup}},[_vm._v(" close ")]),_c('span',{staticClass:"material-symbols-outlined botoes-ajuste add",class:{ disabled: _vm.zoomLevel == 3 },on:{"click":_vm.zoomIn}},[_vm._v(" zoom_in ")]),_c('span',{staticClass:"material-symbols-outlined botoes-ajuste remove",class:{ disabled: _vm.zoomLevel == 1 },on:{"click":_vm.zoomOut}},[_vm._v(" zoom_out ")]),_c('span',{staticClass:"material-symbols-outlined botoes-ajuste restore",on:{"click":_vm.restauraZoom}},[_vm._v(" crop_free ")])])]):_vm._e(),(_vm.abreCarrossel)?_c('div',{staticClass:"popup-overlay",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.fechaCarrossel()}}},[_c('div',{staticClass:"card-carrossel"},[_c('CarrosselSingu',{on:{"fechaCarrossel":_vm.fechaCarrossel}})],1)]):_vm._e(),(_vm.abreListaPerguntas)?_c('div',{staticClass:"popup-overlay",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.fechaListaPerguntas()}}},[_c('ListaPerguntasSugeridas',{attrs:{"listaPerguntas":_vm.listaPerguntas},on:{"enviaPerguntaSelecionada":_vm.enviaPerguntaSelecionada}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }